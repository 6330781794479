import React from 'react';

import classnames from 'classnames';

import Link from 'common/Link';
import nbspLastSpace from 'common/util/nbspLastSpace';
import styles from 'css-module/components/www/LandingTabs/_LandingTabCards.module.scss';

import type { Tab } from 'common/www/LandingV3/LandingTabs/';

type Props = {
  tabs: Tab[];
  currentTabIndex: number;
  setTabIndex: (index: number) => void;
};

const LandingTabCards = ({ tabs, currentTabIndex, setTabIndex }: Props) => {
  return (
    <ul className={styles.container}>
      {tabs.map(({ icon: Icon, title, body, iconClassModifier, url }, index) => {
        const tabContent = (
          <>
            <Icon
              size={24}
              strokeWidth={2}
              className={classnames(
                styles.icon,
                iconClassModifier ? `${styles[iconClassModifier]}` : ''
              )}
            />
            <span className={styles.title}>{title}</span>
            <span className={styles.body}>{nbspLastSpace(body)}</span>
          </>
        );
        return (
          <li
            className={classnames(styles.item, {
              [styles.active]: currentTabIndex === index,
            })}
            key={index}>
            {url ? (
              <Link to={url} className={classnames(styles.button, styles['button--outlined'])}>
                {tabContent}
              </Link>
            ) : (
              <button
                className={classnames(styles.button, styles['button--outlined'])}
                onClick={() => setTabIndex(index)}>
                {tabContent}
              </button>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default LandingTabCards;
