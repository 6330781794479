import React from 'react';

import { ArrowUpWideNarrow, BarChart2, Megaphone, MessagesSquare, Search } from 'lucide-react';

import GenericLandingHelmet, { type Props as Helmet } from 'common/helmets/GenericLandingHelmet';
import { demoLink, registerLink } from 'common/routeComponents/landing/core/constants';
import { WEBPLogos } from 'common/www/assets/CompanyLogos';
import Headshots from 'common/www/assets/Headshots';
import LandingImages from 'common/www/assets/LandingImages';
import LandingContentBlock, { type Props as Block } from 'common/www/LandingV3/LandingContentBlock';
import LandingCTA, { type Props as CTA } from 'common/www/LandingV3/LandingCTA';
import LandingFAQ, { type LandingFAQProps } from 'common/www/LandingV3/LandingFAQ';
import LandingHeading from 'common/www/LandingV3/LandingHeading';
import LandingHero, { type Props as Hero } from 'common/www/LandingV3/LandingHero';
import { LandingHeroImage } from 'common/www/LandingV3/LandingHeroVisual';
import {
  LandingTabsAsLinks,
  type LandingTabsAsLinksProps as Tabs,
} from 'common/www/LandingV3/LandingTabs';
import LandingTestimonials, {
  type Props as Testimonials,
} from 'common/www/LandingV3/LandingTestimonials';

import 'css/components/www/LandingV3/_LandingAutopilotPage.scss';

import type { LandingImage } from 'common/www/LandingV3/types';

const HelmetData: Helmet = {
  title: 'AI-powered feedback management tools',
  description:
    'Autopilot automates feedback collection, management, and analysis. Save time,\n capture all feedback, and uncover valuable customer insights effortlessly.',
  pageURL: 'https://canny.io/features/autopilot',
};

const HeroSection: Hero = {
  copy: {
    pretitle: 'Canny autopilot',
    title: 'AI-powered feedback management tools',
    subtitle:
      'Autopilot automates feedback collection, management, and analysis. Save time,\n capture all feedback, and uncover valuable customer insights effortlessly.',
    cost: 'No credit card required',
    registerButton: 'Get started for free',
  },
  registerLink,
  demoLink,
};

const HeroImage: LandingImage = {
  alt: 'Collect all feedback from different sources in Canny, and see breakdowns of all your data.',
  src: LandingImages.hero['1x'],
  srcSet: [
    {
      src: LandingImages.hero['0.5x'],
      sizeMarker: '640w',
    },
    {
      src: LandingImages.hero['1x'],
      sizeMarker: '1280w',
    },
    {
      src: LandingImages.hero['1.5x'],
      sizeMarker: '1920w',
    },
  ],
  sizes: '(max-width: 420px) 320px, (max-width: 780px) 640px, 960px',
  width: 1920,
  height: 1080,
};

const PreContentBlockHeading = {
  title: 'Put feedback management on Autopilot',
};

const ContentBlocks: Block[] = [
  {
    copy: {
      title: 'Automatically discover and collect feedback',
      subtitle:
        'Integrate with Intercom, Help Scout, Zendesk, Gong, and more to gather feedback. Effortlessly capture every critical insight.',
    },
    image: {
      src: LandingImages.collect,
      alt: 'Many industry standard services can feed into Canny.',
    },
  },
  {
    copy: {
      title: 'Organize and deduplicate feedback',
      subtitle:
        'Keep your feedback board tidy. Automatically detect and merge duplicates. Instantly find the most requested features.',
    },
    image: {
      src: LandingImages.autopilot1_full,
      alt: 'Posts that are detected as duplicates will be merged together, and marked accordingly.',
    },
  },
  {
    copy: {
      title: 'Dive deeper with Smart Replies',
      subtitle:
        'Automatically respond to feedback with follow-up questions. Uncover deeper details and pain points. Make your users feel heard.',
    },
    image: {
      src: LandingImages.autopilot2_full,
      alt: 'A user requests a feature and an automatic reply is sent asking for more details.',
    },
  },
  {
    copy: {
      title: 'Summarize conversations quickly',
      subtitle:
        "Stop spending time reading endless comment threads. Use Comment Summaries to highlight key points so you can understand your users' needs.",
    },
    image: {
      src: LandingImages.autopilot3_full,
      alt: 'A summarize button allows you to quickly summarize comments.',
    },
  },
];

const TestimonialHeading = {
  title: 'How teams use Autopilot',
  subtitle:
    "Don't take our word for it. Teams like yours save time and understand their\n customers better with Autopilot.",
};
const AutopilotTestimonials: Testimonials = {
  copy: { title: '' },
  categorizedTestimonials: {
    general: [
      {
        linkUrl: '/case-studies/',
        logo: {
          alt: 'Orca Scan',
          src: WEBPLogos.OrcaScan,
          height: 32,
          width: 146,
        },
        body: "Canny's Autopilot ensures feature requests never fall through the cracks. We've seen an 80% increase in requests logged since introducing Autopilot.",
        person: {
          imageUrl: Headshots.OwenDoherty,
          name: 'Owen Doherty',
          title: 'COO',
        },
      },
      {
        linkUrl: '/case-studies/',
        logo: {
          alt: 'Credit Repair Cloud',
          src: WEBPLogos.CreditRepairCloud,
          height: 32,
          width: 150,
        },
        body: "I LOVE how it auto-scans our support tickets and magically finds feedback. We've been able to 10x our feedback & remove many duplicate posts.",
        person: {
          imageUrl: Headshots.KeenanJones,
          name: 'Keenan Jones',
          title: 'VP Product',
        },
      },
      {
        linkUrl: '/case-studies/',
        logo: {
          alt: 'Stellar WP',
          src: WEBPLogos.StellarWP,
          height: 32,
          width: 157,
        },
        body: "We're seeing hundreds of support tickets turned into actionable insights both unique and as votes on existing posts with very high accuracy.",
        person: {
          imageUrl: Headshots.MattCromwell,
          name: 'Matt Cromwell',
          title: 'Senior Director, CX',
        },
      },
    ],
  },
};

const FeatureTabs: Tabs = {
  copy: {
    title: 'Autopilot helps you close the feedback loop',
    subtitle: 'Listen to your customers and action their feedback',
  },
  defaultActiveTab: 0,
  tabs: [
    {
      title: 'Feedback discovery',
      body: 'Automatically collect and centralize feedback from all sources',
      icon: MessagesSquare,
      url: '/features/autopilot',
    },
    {
      title: 'Analyze feedback',
      body: 'Review consolidated feedback to inform your product decisions',
      icon: Search,
      url: '/features/analyze-feedback',
    },
    {
      title: 'Prioritize requests',
      body: 'See which features are most requested and important',
      icon: ArrowUpWideNarrow,
      url: '/features/product-roadmap',
    },
    {
      title: 'Build roadmaps',
      body: "Create roadmaps to show customers you're working on their feedback",
      icon: BarChart2,
      iconClassModifier: 'rotate-90',
      url: '/use-cases/public-roadmap',
    },
    {
      title: 'Share updates',
      body: "Publish release notes to let customers know you've acted on their feedback",
      icon: Megaphone,
      url: '/features/product-changelog',
    },
  ],
};

const GradientCTA: CTA = {
  copy: {
    title: 'Activate Autopilot for your team',
    subtitle: 'Automate manual tasks to save time so you can focus on building your product',
    demoButton: 'Request a demo',
    registerButton: 'Get started',
  },
  demoLink,
  registerLink,
  orientation: 'vertical',
};

const FAQ: LandingFAQProps = {
  faqs: [
    {
      question: "Do I have to use all of Autopilot's features?",
      answer: [
        'You can pick and choose which ones you use. Feedback Discovery, Comment Summaries, and Smart Replies can all be used independent of each other.',
      ],
    },
    {
      question: 'Can I review what Autopilot does?',
      answer: [
        "Feedback Discovery and deduplication have two modes — automatic and manual. If you opt to use manual mode, Autopilot will make feedback and deduplication suggestions for you to approve. Automatic mode adds feedback and manages duplicates without any manual effort. You're able to view a log of all actions it took, and undo any of them with a single click.",
      ],
    },
    {
      question: 'How much does it cost?',
      answer: [
        'Autopilot features all use credits.',
        [
          'Feedback Discovery - custom credits for each source',
          'Smart Replies - 1 credit per reply',
          'Comment Summaries - 1 credit per summary',
        ],
        {
          node: (
            <>
              <p>
                You can find more detail on{' '}
                <a
                  href="https://help.canny.io/en/articles/8202451-autopilot#h_3fb0af8a32"
                  rel="noopener"
                  target="_blank">
                  credit usage here
                </a>
                .
              </p>
            </>
          ),
          text: 'You can find more detail on credit usage <a href="https://help.canny.io/en/articles/8202451-autopilot#h_3fb0af8a32" rel="noopener" target="_blank">here</a>',
        },
        'All plans include a monthly allotment of credits:',
        [
          'Free - 25 credits / month',
          'Starter - 100 credits / month',
          'Growth - 500 credits / month',
          'Business - custom',
        ],
        'You can also purchase additional credits for $0.10 each in blocks of 100.',
      ],
    },
    {
      question: 'Is my data secure?',
      answer: [
        "Yes. We don't use your data to train our models.",
        "Autopilot also enjoys the same industry-leading security you'd expect from Canny including being SOC 2 Type 2 compliant.",
      ],
    },
  ],
};

const LandingAutopilotPage = () => {
  return (
    <>
      <GenericLandingHelmet {...HelmetData} />
      <main className="LandingAutopilotPage" id="main">
        <LandingHero {...HeroSection} />
        <LandingHeroImage image={HeroImage} />
        <LandingHeading
          className="LandingAutopilotPage__heading LandingAutopilotPage__heading--hide-on-mobile"
          center
          {...PreContentBlockHeading}
        />
        {ContentBlocks.map((block, index) => {
          return (
            <LandingContentBlock
              {...block}
              flip={index % 2 === 1}
              key={`Autopilot__content-block__${index}`}
            />
          );
        })}
        <LandingHeading className="LandingAutopilotPage__heading" center {...TestimonialHeading} />
        <LandingTestimonials hideHeading {...AutopilotTestimonials} />
        <LandingFAQ {...FAQ} />
        <LandingTabsAsLinks {...FeatureTabs} />
        <LandingCTA {...GradientCTA} className="LandingAutopilotPage__cta" />
      </main>
    </>
  );
};

export default LandingAutopilotPage;
