import React from 'react';

import 'css/components/www/LandingV3/_LandingIntegrations.scss';

import LazyLoadedImage from 'common/LazyLoadedImage';
import Link from 'common/Link';
import nbspLastSpace from 'common/util/nbspLastSpace';
import { type LandingUnresponsiveImage } from 'common/www/LandingV3/types';

type Integration = {
  link: string;
  external?: boolean;
  logo: LandingUnresponsiveImage;
};

type Copy = {
  title: string;
  subtitle: string;
  seeAllLink: string;
};

export type Props = {
  copy: Copy;
  integrations: Integration[][];
};

const LandingIntegrations = ({ copy: { title, subtitle, seeAllLink }, integrations }: Props) => {
  return (
    <section className="LandingIntegrations">
      <h2 className="LandingIntegrations__title">{nbspLastSpace(title)}</h2>
      <p className="LandingIntegrations__subtitle">{nbspLastSpace(subtitle)}</p>
      <Link
        to="/integrations"
        className="LandingIntegrations__see-all LandingIntegrations__see-all--outlined">
        {seeAllLink}
      </Link>
      <nav className="LandingIntegrations__nav-container">
        <ul className="LandingIntegrations__nav-inner-container">
          {integrations.map((row, rowIndex) => {
            return (
              <React.Fragment key={rowIndex}>
                {row.map(({ link, external, logo }, integrationIndex) => {
                  const image = (
                    <LazyLoadedImage
                      {...logo}
                      className="LandingIntegrations__nav-logo"
                      alt={`See details about our ${logo.alt} integration`}
                      loading="lazy"
                    />
                  );
                  return (
                    <li
                      className="LandingIntegrations__nav-item"
                      key={integrationIndex}
                      style={{
                        animationDelay: `${0.1 * (integrationIndex + rowIndex)}s`,
                      }}>
                      {external ? (
                        <a href={link} rel="noopener" className="LandingIntegrations__nav-link">
                          {image}
                        </a>
                      ) : (
                        <Link to={link} className="LandingIntegrations__nav-link">
                          {image}
                        </Link>
                      )}
                    </li>
                  );
                })}
                {/* render manual row spacer */}
                {rowIndex !== integrations.length - 1 && (
                  <li className="LandingIntegrations__nav-line-break"></li>
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </nav>
    </section>
  );
};

export default LandingIntegrations;
