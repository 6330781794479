import React from 'react';

import {
  ArrowLeftRight,
  ArrowUpWideNarrow,
  BarChart2,
  Heart,
  HeartHandshake,
  Lock,
  Megaphone,
  MessagesSquare,
  Search,
  Share,
  Shield,
  Sliders,
  Sparkles,
  Timer,
  TrendingUp,
} from 'lucide-react';

import LandingHelmet from 'common/helmets/LandingHelmet';
import { demoLink, registerLink } from 'common/routeComponents/landing/core/constants';
import { SVGLogos } from 'common/www/assets/CompanyLogos';
import Headshots from 'common/www/assets/Headshots';
import LandingImages from 'common/www/assets/LandingImages';
import LandingBento, { type Props as Bento } from 'common/www/LandingV3/LandingBento';
import LandingContentBlock, { type Props as Block } from 'common/www/LandingV3/LandingContentBlock';
import LandingCTA, { type Props as CTA } from 'common/www/LandingV3/LandingCTA';
import LandingHero, { type Props as Hero } from 'common/www/LandingV3/LandingHero';
import {
  LandingHeroVideo,
  type LandingHeroVideoProps,
} from 'common/www/LandingV3/LandingHeroVisual';
import LandingIntegrations, {
  type Props as Integrations,
} from 'common/www/LandingV3/LandingIntegrations';
import LandingLogos, { type Props as Logos } from 'common/www/LandingV3/LandingLogos';
import LandingStats, { type Props as Stats } from 'common/www/LandingV3/LandingStats';
import {
  LandingTabsWithBody,
  type TabWithBodyProps as Tabs,
} from 'common/www/LandingV3/LandingTabs';
import LandingTestimonials, {
  type Props as Testimonials,
} from 'common/www/LandingV3/LandingTestimonials';

import 'css/components/www/_LandingV3.scss';

const VideoID = 'pil7s7hbw3';

const heroSection: Hero = {
  copy: {
    title: 'Build better products with customer feedback',
    subtitle:
      'Centralize product feedback to uncover insights and make\n informed product decisions',
    demoButton: 'Request a demo',
    registerButton: 'Get started',
    cost: 'No credit card required',
  },
  registerLink: registerLink,
  demoLink: demoLink,
};

const videoSection: LandingHeroVideoProps = {
  copy: {
    playButton: 'See how it works',
  },
  videoID: VideoID,
  image: {
    alt: 'Collect all feedback from different sources in Canny, and see breakdowns of all your data.',
    src: LandingImages.hero['1x'],
    srcSet: [
      {
        src: LandingImages.hero['0.5x'],
        sizeMarker: '640w',
      },
      {
        src: LandingImages.hero['1x'],
        sizeMarker: '1280w',
      },
      {
        src: LandingImages.hero['1.5x'],
        sizeMarker: '1920w',
      },
    ],
    sizes: '(max-width: 420px) 320px, (max-width: 780px) 640px, 960px',
    width: 1920,
    height: 1080,
  },
};

const logosSection: Logos = {
  copy: {
    title: 'Trusted by both industry leaders and startups',
  },
  logos: [
    // first row
    {
      company: 'ahrefs',
      url: '/case-studies/ahrefs',
      image: {
        src: SVGLogos.Ahrefs,
        alt: '',
        width: 110,
        height: 28,
      },
    },
    {
      company: 'circleci',
      url: '/case-studies/circleci',
      image: {
        src: SVGLogos.CircleCI,
        alt: '',
        height: 28,
        width: 134,
      },
    },
    {
      company: 'hive',
      url: '/case-studies/hive',
      image: {
        src: SVGLogos.Hive,
        alt: '',
        height: 32,
        width: 136,
      },
    },
    {
      company: 'axios',
      url: '/case-studies/axios',
      image: {
        src: SVGLogos.Axios,
        alt: '',
        height: 28,
        width: 110,
      },
    },
    {
      company: 'clickup',
      url: '/case-studies/clickup',
      image: {
        src: SVGLogos.ClickUp,
        alt: '',
        height: 32,
        width: 131,
      },
    },
    // second row
    {
      company: 'loom',
      url: '/case-studies/',
      image: {
        src: SVGLogos.Loom,
        alt: '',
        height: 28,
        width: 86,
      },
    },
    {
      company: 'flock',
      url: '/case-studies/',
      image: {
        src: SVGLogos.Flock,
        alt: '',
        height: 28,
        width: 82,
      },
    },
    {
      company: 'mercury',
      url: '/case-studies/mercury',
      image: {
        src: SVGLogos.Mercury,
        alt: '',
        height: 28,
        width: 152,
      },
    },
    {
      company: 'strapi',
      url: '/case-studies/strapi',
      image: {
        src: SVGLogos.Strapi,
        alt: '',
        height: 32,
        width: 134,
      },
    },
    {
      company: 'missive',
      url: '/case-studies/missive',
      image: {
        src: SVGLogos.Missive,
        alt: '',
        height: 32,
        width: 182,
      },
    },
  ],
};

const tabsSection: Tabs = {
  copy: {
    title: 'Your complete customer feedback platform',
    subtitle:
      'Organize all your feedback in one place, prioritize what to build next, and keep everyone updated',
  },
  tabs: [
    {
      title: 'Collect feedback',
      body: 'Gather, analyze, and organize feedback in a centralized location',
      icon: MessagesSquare,
      image: {
        src: LandingImages.collect,
        alt: 'Many industry standard services can feed into Canny.',
        width: 1532,
        height: 1440,
      },
      imageStyleAdjustments: { top: 0 },
      tabBody: [
        'Stop letting ideas slip through the cracks. Collect, analyze, and organize feedback and feature requests in one dedicated tool.',
        [
          'Automatically capture customer feedback from conversations with Autopilot',
          'Detect and merge duplicates so you can quantify ideas',
          'Set up a feedback portal to let users share requests directly from your site',
          'Integrate customer support tools to let your team capture feedback on behalf of users',
        ],
      ],
    },
    {
      title: 'Analyze feedback',
      body: 'Uncover valuable customer insights to make better product decisions',
      icon: Search,
      image: {
        src: LandingImages.analyze,
        alt: 'Get breakdown tables with information such as company spend, renewal status, and priority.',
        width: 1532,
        height: 1172,
      },
      imageStyleAdjustments: {
        top: '10%',
      },
      tabBody: [
        'Uncover valuable customer insights to make better product decisions',
        [
          'See which features could have the highest revenue impact',
          'Connect customer data to see who wants which features',
          'Create user segments to see feedback from groups you care about most',
          'Clarify use cases up front and get more context from users',
        ],
      ],
    },
    {
      title: 'Prioritize requests',
      body: 'Develop formulas to automatically score feedback and feature requests',
      icon: ArrowUpWideNarrow,
      image: {
        src: LandingImages.prioritize,
        alt: 'Use inputs such as impact and priority to score your tasks.',
        width: 1246,
        height: 1288,
      },
      imageStyleAdjustments: {
        paddingRight: 33,
        top: '10%',
      },
      tabTitle: 'Prioritize feature requests',
      tabBody: [
        'Build your prioritization formula to score feedback and feature requests. Focus on the features with the biggest impact.',
        [
          'Customize your formula with impact and effort factor weights',
          'Create custom post fields unique to your business',
          'Sync properties from Hubspot or Salesforce to factor in opportunity revenue',
          'Seamlessly integrate with your project management tool of choice',
        ],
      ],
    },
    {
      title: 'Build roadmaps',
      body: 'Create public or private roadmaps to keep everyone updated on your progress',
      icon: BarChart2,
      iconClassModifier: 'rotate-90',
      image: {
        src: LandingImages.roadmaps,
        alt: 'Create roadmap tables where you can view status, impact, votes, and where each task comes from.',
        width: 1452,
        height: 1352,
      },
      imageStyleAdjustments: {
        top: '7%',
      },
      tabTitle: 'Build your roadmap',
      tabBody: [
        'Create a public or private roadmap to keep everyone updated',
        [
          'Add your top feature requests to your roadmap in a single click',
          'Connect to project management tools like Jira, ClickUp, and Asana',
          'Create roadmaps for specific projects or use cases',
        ],
      ],
    },
    {
      title: 'Share updates',
      body: 'Build a public changelog to keep your audience informed and engaged',
      icon: Megaphone,
      image: {
        src: LandingImages.changelog,
        alt: 'Write changelogs, adjust privacy and email notification settings, and more.',
        width: 1180,
        height: 1331,
      },
      imageStyleAdjustments: {
        paddingRight: 70,
        top: '7%',
      },
      tabBody: [
        'Build a changelog to keep everyone engaged',
        [
          'Publish detailed release notes linked to your feature requests',
          'Automatically notify users who voted on those feature requests',
          'Boost customer engagement, retention, and feature adoption',
        ],
      ],
    },
  ],
};

const aiBentoBoxSection: Bento = {
  copy: {
    title: 'Put feedback management on Autopilot',
    subtitle: 'Powerful AI features handle as many of your manual feedback tasks as you like',
  },
  boxes: [
    {
      backgroundColor: 'indigo50',
      title: 'Feedback Discovery',
      body: 'Automatically capture and deduplicate customer feedback from customer conversations',
      image: {
        src: LandingImages.autopilot1,
        alt: 'Many different sources such as Canny, Intercom, Help Scout, Gong, and G2 Reviews can feed into Canny.',
        width: 897,
        height: 302,
      },
    },
    {
      backgroundColor: 'sky50',
      title: 'Smart Replies',
      body: 'Respond to feedback with clarifying questions to uncover deeper insights',
      image: {
        src: LandingImages.autopilot2,
        alt: 'A user requests a feature and an automatic reply is sent asking for more details.',
        width: 584,
        height: 298,
      },
    },
    {
      backgroundColor: 'blue50',
      title: 'Comment Summaries',
      body: 'Instantly recap lengthy feedback threads to understand core use cases',
      image: {
        src: LandingImages.autopilot3,
        alt: 'A summarize button allows you to quickly summarize comments.',
        width: 584,
        height: 298,
      },
    },
  ],
};

const statsSection: Stats = {
  copy: {
    title: 'Leading companies use Canny to close the feedback loop',
  },
  stats: [
    {
      metric: '50K+',
      subtitle: 'registered companies',
    },
    {
      metric: '1M+',
      subtitle: 'product updates sent',
    },
    {
      metric: '15M+',
      subtitle: 'feedback items captured',
    },
  ],
};

const contentBlocks: Block[] = [
  {
    copy: {
      title: 'Create a community around your product',
      subtitle: 'Put your users center-stage to share their feedback and discuss ideas',
    },
    image: {
      alt: 'An email following up to a specific user for a feedpack post marked as complete.',
      src: LandingImages.block1['1x'],
      srcSet: [
        {
          src: LandingImages.block1['0.75x'],
          sizeMarker: '372w',
        },
        {
          src: LandingImages.block1['1x'],
          sizeMarker: '744w',
        },
        {
          src: LandingImages.block1['2x'],
          sizeMarker: '1488w',
        },
      ],
      sizes: '(max-width: 420px) 186px, (max-width: 780px) 372px, 744px',
      width: 744,
      height: 580,
    },
    points: [
      {
        icon: MessagesSquare,
        title: 'Discuss',
        body: 'Foster discussion about your product and feature requests',
      },
      {
        icon: Search,
        title: 'Understand',
        body: 'Learn more about user motivations and pain points',
      },
      {
        icon: Share,
        title: 'Share',
        body: 'Keep customers updated as you\u00a0work on their ideas',
      },
      {
        icon: Heart,
        title: 'Engage',
        body: 'Let your team connect with your most engaged users',
      },
    ],
  },
  {
    copy: {
      title: "Built to support your team's needs",
      subtitle: 'Thousands of teams like yours rely on Canny to help them build better products',
    },
    image: {
      alt: 'Options such as tags and user permissions, to allow the following of SOC & GDPR compliance.',
      src: LandingImages.block2['1x'],
      srcSet: [
        {
          src: LandingImages.block2['0.75x'],
          sizeMarker: '372w',
        },
        {
          src: LandingImages.block2['1x'],
          sizeMarker: '744w',
        },
        {
          src: LandingImages.block2['2x'],
          sizeMarker: '1488w',
        },
      ],
      sizes: '(max-width: 420px) 186px, (max-width: 780px) 372px, 744px',
      width: 744,
      height: 600,
    },
    points: [
      {
        icon: TrendingUp,
        title: 'Scalable',
        body: 'Grow from startup to enterprise\u00a0with flexible plans',
      },
      {
        icon: HeartHandshake,
        title: 'Supportive',
        body: 'Rely on our award-winning customer support',
      },
      {
        icon: Shield,
        title: 'Secure',
        body: 'Rest easy with best-in-class security and compliance',
      },
      {
        icon: Lock,
        title: 'Private',
        body: 'Control whether your feedback and roadmaps are public\u00a0or private',
      },
    ],
  },
  {
    copy: {
      title: 'Easy to set up and use',
      subtitle: "Built with usability in mind. From quick setup to daily use, you'll love using it",
    },
    image: {
      alt: "Customization of a company's color and theme.",
      src: LandingImages.block3['1x'],
      srcSet: [
        {
          src: LandingImages.block3['0.75x'],
          sizeMarker: '390w',
        },
        {
          src: LandingImages.block3['1x'],
          sizeMarker: '780w',
        },
        {
          src: LandingImages.block3['2x'],
          sizeMarker: '1560w',
        },
      ],
      sizes: '(max-width: 420px) 195px, (max-width: 780px) 390px, 780px',
      width: 780,
      height: 566,
    },
    points: [
      {
        icon: Timer,
        title: 'Quick',
        body: 'Install Canny and start collecting feedback in minutes',
      },
      {
        icon: ArrowLeftRight,
        title: 'Easy',
        body: 'Import your existing feedback with a simple upload',
      },
      {
        icon: Sparkles,
        title: 'User-friendly',
        body: 'Enjoy working with a modern and intuitive UI',
      },
      {
        icon: Sliders,
        title: 'Customizable',
        body: 'Customize Canny to match your brand and site identity',
      },
    ],
  },
];

const integrationsSection: Integrations = {
  copy: {
    title: 'Works well with your existing workflow',
    subtitle:
      'Canny integrates with tools you already use for customer service, project management, and more',
    seeAllLink: 'See all integrations',
  },
  integrations: [
    // first row
    [
      {
        logo: {
          src: SVGLogos.Slack,
          alt: 'Slack',
          width: 96,
          height: 96,
        },
        link: '/integrations/slack',
      },
      {
        logo: {
          src: LandingImages.ColorfulClickupLogo,
          alt: 'Click Up',
          width: 96,
          height: 96,
        },
        link: '/integrations/clickup',
      },
      {
        logo: {
          src: SVGLogos.Zapier,
          alt: 'Zapier',
          width: 96,
          height: 96,
        },
        link: 'https://zapier.com/apps/canny/integrations',
        external: true,
      },
      {
        logo: {
          src: SVGLogos.Jira,
          alt: 'Jira',
          width: 96,
          height: 96,
        },
        link: '/integrations/jira',
      },
      {
        logo: {
          src: LandingImages.AsanaLogo,
          alt: 'Asana',
          width: 104,
          height: 96,
        },
        link: '/integrations/asana',
      },
    ],
    // second row
    [
      {
        logo: {
          src: SVGLogos.Intercom,
          alt: 'Intercom',
          width: 96,
          height: 96,
        },
        link: '/integrations/intercom',
      },
      {
        logo: {
          src: SVGLogos.Salesforce,
          alt: 'Salesforce',
          width: 137,
          height: 96,
        },
        link: '/integrations/salesforce',
      },
      {
        logo: {
          src: LandingImages.HubspotLogo,
          alt: 'Hubspot',
          width: 96,
          height: 96,
        },
        link: '/integrations/hubspot',
      },
      {
        logo: {
          src: SVGLogos.GitHub,
          alt: 'GitHub',
          width: 98,
          height: 96,
        },
        link: '/integrations/github',
      },
      {
        logo: {
          src: SVGLogos.Zendesk,
          alt: 'Zendesk',
          width: 127,
          height: 96,
        },
        link: '/integrations/zendesk',
      },
    ],
  ],
};

const testimonialSection: Testimonials = {
  copy: {
    title: "Here's why thousands of\u00a0teams count on Canny",
  },
  categorizedTestimonials: {
    product: [
      {
        linkUrl: '/case-studies/circleci',
        logo: {
          alt: 'Circle CI',
          src: SVGLogos.CircleCI,
          height: 32,
          width: 153,
        },
        body: 'It just makes our lives easier from the product perspective, to be able to go to this one tool and see everything.',
        person: {
          imageUrl: Headshots.LiyaAi,
          name: 'Liya Ai',
          title: 'Product Operations Manager',
        },
      },
      {
        linkUrl: '/case-studies/agencyanalytics',
        logo: {
          alt: 'Agency Analytics',
          src: SVGLogos.AgencyAnalytics,
          height: 32,
          width: 218,
        },
        body: 'Thanks to Canny, we find the issues that are most requested, avoid churn, and generate more revenue.',
        person: {
          imageUrl: Headshots.JayPatel,
          name: 'Jay Patel',
          title: 'Product Manager',
        },
      },
      {
        linkUrl: '/case-studies/mercury',
        logo: {
          alt: 'Mercury',
          src: SVGLogos.Mercury,
          height: 32,
          width: 173,
        },
        body: "We use Canny as a way to distribute product thinking across the team. Now that everything is in one place, it's so much easier and more efficient.",
        person: {
          imageUrl: Headshots.IdaStrom,
          name: 'Ida Ström',
          title: 'Senior Product Designer',
        },
      },
    ],
    success: [
      {
        linkUrl: '/case-studies/aryeo',
        logo: {
          alt: 'Aryeo',
          src: SVGLogos.Aryeo,
          height: 32,
          width: 117,
        },
        body: 'Canny has reduced the number of inbounds in our support inbox by 20%, which is a big deal.',
        person: {
          imageUrl: Headshots.ChelseaDarby,
          name: 'Chelsea Darby',
          title: 'Customer Success',
        },
      },
      {
        linkUrl: '/case-studies/figured',
        logo: {
          alt: 'Figured',
          src: SVGLogos.Figured,
          height: 32,
          width: 92,
        },
        body: 'Suddenly, everything from Canny to Intercom to Slack was linked. We were actually able to record what people wanted in one place, with basically no effort.',
        person: {
          imageUrl: Headshots.NickBarraclough,
          name: 'Nick Barraclough',
          title: 'Partner Success Manager',
        },
      },
      {
        linkUrl: '/case-studies/knak',
        logo: {
          alt: 'Knak',
          src: SVGLogos.Knak,
          height: 32,
          width: 104,
        },
        body: "Gathering and analyzing feedback is so much more quick and efficient now, and it's saving us a lot of time.",
        person: {
          imageUrl: Headshots.FelixHiggs,
          name: 'Felix Higgs',
          title: 'Senior CSM',
        },
      },
    ],
    sales: [
      {
        linkUrl: '/case-studies/strapi',
        logo: {
          alt: 'Strapi',
          src: SVGLogos.Strapi,
          height: 32,
          width: 134,
        },
        body: "Canny even helps us win deals. When a prospect requests a feature, it's easy for the sales team to log it in and follow up once it's shipped.",
        person: {
          imageUrl: Headshots.AurelienGeorget,
          name: 'Aurélien Georget',
          title: 'Chief Product Officer',
        },
      },
      {
        linkUrl: '/case-studies/janetechnologies',
        logo: {
          alt: 'Jane',
          src: SVGLogos.Jane,
          height: 32,
          width: 121,
        },
        body: 'When our sales reps are talking to a prospect, they often hear a request for a very specific feature. Now, they can quickly log that in Canny and mention how important it is.',
        person: {
          imageUrl: Headshots.RachelSchaaf,
          name: 'Rachel Schaaf',
          title: 'Senior Director',
        },
      },
      {
        linkUrl: '/case-studies/givebutter',
        logo: {
          alt: 'GiveButter',
          src: SVGLogos.GiveButter,
          height: 32,
          width: 208,
        },
        body: 'As the sales team is working with the prospect, they use Canny to add new requests and upvote existing ones on behalf of those prospects. That really signals to us that we should prioritize certain features.',
        person: {
          imageUrl: Headshots.JesseSandala,
          name: 'Jesse Sandala',
          title: 'Director of Product',
        },
      },
    ],
    marketing: [
      {
        linkUrl: '/case-studies/hive',
        logo: {
          alt: 'Hive',
          src: SVGLogos.Hive,
          height: 32,
          width: 136,
        },
        body: "Canny is saving us about 2-3 hours per week on product marketing. Instead of going back and forth between various tools, everything lives in Canny now. It's our one source of truth.",
        person: {
          imageUrl: Headshots.MichaelaRollings,
          name: 'Michaela Rollings',
          title: 'Head of Brand & Content',
        },
      },
      {
        linkUrl: '/case-studies/axios',
        logo: {
          alt: 'Axios',
          src: SVGLogos.Axios,
          height: 32,
          width: 126,
        },
        body: 'Canny really helped us to achieve product-market fit for the app during the beta period.',
        person: {
          imageUrl: Headshots.MarcusMoretti,
          name: 'Marcus Moretti',
          title: 'Senior Product Director',
        },
      },
      {
        linkUrl: '/case-studies/agencyanalytics',
        logo: {
          alt: 'Agency Analytics',
          src: SVGLogos.AgencyAnalytics,
          height: 32,
          width: 218,
        },
        body: 'Thanks to Canny, we find the issues that are most requested, avoid churn, and generate more revenue.',
        person: {
          imageUrl: Headshots.JayPatel,
          name: 'Jay Patel',
          title: 'Product Manager',
        },
      },
    ],
    founders: [
      {
        linkUrl: '/case-studies/clickup',
        logo: {
          alt: 'Click Up',
          src: SVGLogos.ClickUp,
          height: 32,
          width: 131,
        },
        body: "Canny removes our need for user testing entirely, which is something I didn't really anticipate.",
        person: {
          imageUrl: Headshots.ZebEvans,
          name: 'Zeb Evans',
          title: 'CEO',
        },
      },
      {
        linkUrl: '/case-studies/missive',
        logo: {
          alt: 'Missive',
          src: SVGLogos.Missive,
          height: 32,
          width: 182,
        },
        body: "Canny really helps us not just with feedback management, but also with decision-making. It pushes us to be more creative with what we're building next. Because, Canny helps us balance user ideas with our own input.",
        person: {
          imageUrl: Headshots.PhilippeLehoux,
          name: 'Philippe Lehoux',
          title: 'CEO',
        },
      },
      {
        linkUrl: '/case-studies/document360',
        logo: {
          alt: 'Document360',
          src: SVGLogos.Document360,
          height: 32,
          width: 242,
        },
        body: "Canny came out on top for two reasons: it's very simple to use, and our users love it.",
        person: {
          imageUrl: Headshots.SaravanaKumar,
          name: 'Saravana Kumar',
          title: 'Founder',
        },
      },
    ],
  },
};

const cta: CTA = {
  copy: {
    title: 'Ready to get started?',
    demoButton: 'Request a demo',
    registerButton: 'Get started',
  },
  registerLink,
  demoLink,
};

const LandingV3 = () => {
  return (
    <>
      <LandingHelmet />
      <main className="LandingV3" id="main">
        <LandingHero {...heroSection} />
        <LandingHeroVideo {...videoSection} />
        <LandingLogos {...logosSection} />
        <LandingTabsWithBody {...tabsSection} />
        <LandingBento {...aiBentoBoxSection} />
        <LandingStats {...statsSection} />
        {contentBlocks.map((block, index) => (
          <LandingContentBlock {...block} key={index} flip={index % 2 === 1} />
        ))}
        <LandingIntegrations {...integrationsSection} />
        <LandingTestimonials {...testimonialSection} />
        <LandingCTA {...cta} />
      </main>
    </>
  );
};

export default LandingV3;
