import analyze from 'img/landing/analyze.webp';
import AsanaLogo from 'img/landing/asana-logo.webp';
import autopilot1_full from 'img/landing/autopilot-1-full.webp';
import autopilot1 from 'img/landing/autopilot-1.webp';
import autopilot2_full from 'img/landing/autopilot-2-full.webp';
import autopilot2 from 'img/landing/autopilot-2.webp';
import autopilot3_full from 'img/landing/autopilot-3-full.webp';
import autopilot3 from 'img/landing/autopilot-3.webp';
import block1_0p75x from 'img/landing/block-1-0.75x.webp';
import block1_1x from 'img/landing/block-1-1x.webp';
import block1_2x from 'img/landing/block-1-2x.webp';
import block2_0p75x from 'img/landing/block-2-0.75x.webp';
import block2_1x from 'img/landing/block-2-1x.webp';
import block2_2x from 'img/landing/block-2-2x.webp';
import block3_0p75x from 'img/landing/block-3-0.75x.webp';
import block3_1x from 'img/landing/block-3-1x.webp';
import block3_2x from 'img/landing/block-3-2x.webp';
import changelog from 'img/landing/changelog.webp';
import ColorfulClickupLogo from 'img/landing/clickup-logo.webp';
import collect from 'img/landing/collect.webp';
import hero_0p5x from 'img/landing/hero-0.5x.webp';
import hero_1p5x from 'img/landing/hero-1.5x.webp';
import hero_1x from 'img/landing/hero-1x.webp';
import HubspotLogo from 'img/landing/hubspot-logo.webp';
import prioritize from 'img/landing/prioritize.webp';
import roadmaps from 'img/landing/roadmaps.webp';

const LandingImages = {
  roadmaps,
  prioritize,
  hero: {
    '0.5x': hero_0p5x,
    '1x': hero_1x,
    '1.5x': hero_1p5x,
  },
  collect,
  changelog,
  block3: {
    '0.75x': block3_0p75x,
    '1x': block3_1x,
    '2x': block3_2x,
  },
  block2: {
    '0.75x': block2_0p75x,
    '1x': block2_1x,
    '2x': block2_2x,
  },
  block1: {
    '0.75x': block1_0p75x,
    '1x': block1_1x,
    '2x': block1_2x,
  },
  autopilot1,
  autopilot2,
  autopilot3,
  autopilot1_full,
  autopilot2_full,
  autopilot3_full,
  analyze,
  HubspotLogo,
  ColorfulClickupLogo,
  AsanaLogo,
};
export default LandingImages;
